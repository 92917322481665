 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }// @ts-check

import { clientsClaim } from "workbox-core";
import { BroadcastUpdatePlugin } from "workbox-broadcast-update";

import { createHandlerBoundToURL } from "workbox-precaching";
import { NavigationRoute } from "workbox-routing/NavigationRoute";
import {
  precacheAndRoute,
  addPlugins as precachePlugins,
} from "workbox-precaching";
import { registerRoute } from "workbox-routing/registerRoute";

// @ts-ignore
const cacheManifest = self.__WB_MANIFEST;

// @ts-ignore
const skipWaiting = () => self.skipWaiting();

skipWaiting();
clientsClaim();

precachePlugins([new BroadcastUpdatePlugin()]);
precacheAndRoute(cacheManifest);

registerRoute(new NavigationRoute(createHandlerBoundToURL("index.html")));

self.addEventListener("message", (event) => {
  if (event.data && event.data.type === "SKIP_WAITING") {
    skipWaiting();
  }
});

// Register event listener for the 'push' event.
self.addEventListener("push", function (event) {
  // Retrieve the textual payload from event.data (a PushMessageData object).
  // Other formats are supported (ArrayBuffer, Blob, JSON), check out the documentation
  // on https://developer.mozilla.org/en-US/docs/Web/API/PushMessageData.
  try {
    const payload = _optionalChain([event, 'access', _ => _.data, 'optionalAccess', _2 => _2.json, 'call', _3 => _3()]);

    if (payload.type === "notification") {
      // Keep the service worker alive until the notification is created.
      event.waitUntil(
        self.registration.showNotification(payload.title, payload.options)
      );
    }
  } catch (error) {
    console.error("Push Message Parsing Error:", error);
  }
});

self.addEventListener("notificationclick", function (event) {
  _optionalChain([event, 'access', _4 => _4.notification, 'optionalAccess', _5 => _5.close, 'call', _6 => _6()]);

  const data = _optionalChain([event, 'access', _7 => _7.notification, 'optionalAccess', _8 => _8.data]);

  if (_optionalChain([data, 'optionalAccess', _9 => _9.ridesharing]) && _optionalChain([data, 'optionalAccess', _10 => _10.chat])) {
    clients.openWindow(`/ride-sharing/messenger?chat=${_optionalChain([data, 'optionalAccess', _11 => _11.chat])}`);
  }
});
